import axios from '@axios'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
import {checkResponseStatus} from '../checkResponseStatus'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/users', { params: queryParams })
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/users/${id}`)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    editUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/users/${id}/edit`)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/users/${requestData.id}`, requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateAccount(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/user/${requestData.id}/update-account`, requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addUser(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/users', requestData )
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/user/change-password', requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changePermission(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/user/change-permission', requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchDesignationOption(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/option/designation')
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchRoleOption(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/option/role')
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changeStatus(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/user/${requestData.id}/change-archive-status`, requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  }
}
