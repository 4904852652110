<template>

  <div>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :designation-options="designationOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <users-list-filters
      :role-filter.sync="roleFilter"
      :designation-filter.sync="designationFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :designation-options="designationOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('create', 'User')"
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(full_name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.image_path"
                :text="avatarText(data.item.full_name)"
                :to="{ name: 'user-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'user-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.full_name }}
            </b-link>
            <small v-if="data.item.designation_id" class="text-muted">@{{ data.item.designation.designation }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <span v-if="data.item.role" class="align-text-top text-capitalize">{{ data.item.role.name }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(is_archived)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.is_archived)}`"
            class="text-capitalize"
          >
            {{ showStatus(data.item.is_archived) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'user-view', params: { id: data.item.id } }">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="$can('edit', 'User') && getStorageUserData.email != data.item.email && !data.item.is_admin && !data.item.is_super" :to="{ name: 'user-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="$can('delete', 'User') && getStorageUserData.email != data.item.email && !data.item.is_admin && !data.item.is_super && !data.item.is_archived" @click="changeStatus(data.item.id, 1)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Archive</span>
            </b-dropdown-item>

            <b-dropdown-item v-else-if="$can('delete', 'User') && getStorageUserData.email != data.item.email && !data.item.is_admin && !data.item.is_super && data.item.is_archived" @click="changeStatus(data.item.id, 0)">
              <feather-icon icon="RefreshCwIcon" />
              <span class="align-middle ml-50">Unarchive</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import { getStorageUserData } from '@/auth/utils'

export default {
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    // change archive starus
    changeStatus(id, is_archived) {
      var status = is_archived ? 'archive' : 'unarchive'
      this.$swal({
        title: 'Are you confirm?',
        text: `You want to ${status} this!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-user/changeStatus', {id: id, is_archived: is_archived})
            .then((response) => {
              if(response.data.status == 0 || response.data.status == 1){
                this.$swal({
                  icon: 'success',
                  title: response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.refetchData();
              }
            })
          
        }
      })
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const getDesignationData = async () => {
      store.dispatch('app-user/fetchDesignationOption')
        .then((response) => {
          if(response.data.status == 1)
            designationOptions.value = response.data.data
      })
    }
    const getRoleData = async () => {
      store.dispatch('app-user/fetchRoleOption')
        .then((response) => {
          if(response.data.status == 1)
            roleOptions.value = response.data.data
      })
    }

    onMounted(getDesignationData)
    onMounted(getRoleData)

    const isAddNewUserSidebarActive = ref(false)

    const designationOptions = ref([])
    const roleOptions = ref([])

    const statusOptions = [
      { label: 'All', value: null },
      { label: 'Active', value: 0 },
      { label: 'Archived', value: 1 },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      showStatus,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      designationFilter,
      statusFilter,
    } = useUsersList()

    return {
      getStorageUserData,
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      showStatus,
      resolveUserStatusVariant,

      roleOptions,
      designationOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      designationFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
